@charset 'UTF-8';
@import "/Users/VuongHuy/Website/vhost/kyohoucorp/src/scss/utility/_burger.scss";
@import "/Users/VuongHuy/Website/vhost/kyohoucorp/src/scss/utility/_flex.scss";
@import "/Users/VuongHuy/Website/vhost/kyohoucorp/src/scss/utility/_media-queries.scss";
@import "/Users/VuongHuy/Website/vhost/kyohoucorp/src/scss/utility/_mixin.scss";

.top {
  .pace.pace-inactive {
    display: none;
  }
  .pace {
    -webkit-pointer-events: none;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    z-index: 9999;
    position: fixed;
    height: 100vh;
    width: 100%;
    margin: auto;
    background: #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
  }
  .pace .pace-progress {
    z-index: 9999;
    width: 100%;
    height: 128px;
    left: 50%;
    top: 50%;
    position: absolute;
    -webkit-transform: translate(-50%,-50%) !important;
    -ms-transform: translate(-50%,-50%) !important;
    transform: translate(-50%,-50%) !important;
  }
  .pace .pace-progress:before {
    content: 'Loading \A' attr(data-progress-text);
    white-space: pre;
    text-align: center;
    color: #8f8f8f;
    @include font(18,35);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: block;
    z-index: 999;
    position: absolute;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
  }
  .pace .pace-progress:after {
    content: '';
    background: url('../../img/common/logo.svg') 0 0/100% auto no-repeat;
    width: 270px;
    height: 30px;
    position: absolute;
    left: 50%;
    top: 0;
    z-index: 999;
    margin-top: -60px;
    @include translate(-50%,0);
    @include SP {
      width: 200px;
      height: 22px;
    }
  }
  .pace .pace-activity {
    -webkit-animation: pace-theme-center-atom-spin 2s linear infinite;
    -moz-animation: pace-theme-center-atom-spin 2s linear infinite;
    -o-animation: pace-theme-center-atom-spin 2s linear infinite;
    animation: pace-theme-center-atom-spin 2s linear infinite;
  }
  .pace .pace-activity {
    width: 124px;
    height: 128px;
    background: url('../../img/common/icon/ico_loading.svg') 0 0/100% auto no-repeat;
  }
  @-webkit-keyframes pace-theme-center-atom-spin {
    0%   { -webkit-transform: rotate(0deg) }
    100% { -webkit-transform: rotate(359deg) }
  }
  @-moz-keyframes pace-theme-center-atom-spin {
    0%   { -moz-transform: rotate(0deg) }
    100% { -moz-transform: rotate(359deg) }
  }
  @-o-keyframes pace-theme-center-atom-spin {
    0%   { -o-transform: rotate(0deg) }
    100% { -o-transform: rotate(359deg) }
  }
  @keyframes pace-theme-center-atom-spin {
    0%   { transform: rotate(0deg) }
    100% { transform: rotate(359deg) }
  }
  &__mainvisual {
    .img {
      img {
        display: block;
        width: 100%;
      }
    }
    .owl-dots {
      position: relative;
      line-height: 0;
      font-size: 0;
      bottom: 20px;
      padding: 0 20px;
      margin: -3px auto 0;
      z-index: 10;
      @include min-screen(768px) {
        width: 720px;
      }
      @include min-screen(992px) {
        width: 940px;
      }
      @include min-screen(1300px) {
        width: 1210px;
      }
      @include SP {
        display: none;
      }
      button.owl-dot {
        margin: 0 5px 0 0;
        span {
          display: block;
          width: 20px;
          height: 3px;
          background: rgba(255,255,255,.35);
        }
        &.active {
          span {
            background: #fff;
          }
        }
      }
    }
  }

  &__news {
    height: 50px;
    position: relative;
    @include SP {
      background: $color_blue;
      height: auto;
      padding: 8px 0 12px;
    }
    &:after {
      content: '';
      width: 50%;
      height: 100%;
      z-index: -1;
      right: 0;
      top: 0;
      background: $color_blue;
      position: absolute;
      @include SP {
        display: none;
      }
    }
    .container {
      @include flexbox();
      height: 100%;
      @include SP {
        display: block;
      }
    }
    &--title {
      color: $color_blue;
      font-weight: 600;
      padding: 0 10px 0 0;
      width: 32.83%;
      @include flexbox();
      @include align-items(center);
      @include justify-content(flex-end);
      @include font(18,30);
      @include SP {
        @include justify-content(flex-start);
        @include font(16,28);
        width: 100%;
        padding: 0;
        color: #fff;
        background: $color_blue;
      }
    }
    &--content {
      background: $color_blue url('../../img/top/news_bg.jpg') left top/auto 50px no-repeat;
      width: 67.17%;
      @include flexbox();
      @include justify-content(flex-end);
      @include align-items(center);
      @include SP {
        background: none;
        width: 100%;
        display: block;
        padding: 3px 0 0;
      }
      .ctn-inner {
        max-width: 490px;
        width: 100%;
        text-align: center;
        @include SP {
          text-align: left;
        }
      }
      .item {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        line-height: 0;
        color: #fff;
        .date,
        .tit a {
          @include font(14,24.5);
          color: #fff;
          @include SP {
            @include font(13,22.75);
          }
        }
        .date {
          overflow: hidden;
          padding: 0 10px 0 0;
          display: inline;
          vertical-align: middle;
          @include SP {
            display: block;
            @include font(10,17.5);
            padding: 0;
          }
        }
        .tit {
          display: inline;
          vertical-align: middle;
          @include SP {
            display: block;
          }
        }
      }
    }
  }

  &__car-dress {
    padding: 108px 0 60px;
    @include SP {
      padding: 42px 0 15px;
    }
    .title {
      text-align: center;
      @include font(34,59.5);
      font-weight: 700;
      margin: 0 0 20px;
      letter-spacing: 0.03em;
      color: #333;
      @include SP {
        @include font(24,42);
        text-align: left;
        margin: 0 0 15px;
      }
      @include ANDR {
        @include font(20);
      }
      span {
        color: $color_blue;
        padding-left: 3px;
      }
    }
    .cm-txt {
      text-align: center;
      @include SP {
        text-align: left;
      }
      p {
        color: #333;
      }
    }
  }

  &__product-news {
    background: rgba(0,104,172,.3);
    padding: 73px 0;
    @include SP {
      padding: 45px 0;
    }
    &--info {
      .cm-txt {
        text-align: center;
        @include SP {
          text-align: left;
        }
      }
    }
    &--list {
      margin: 40px 0 0;
      .list-pro {
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify-content(space-between);
        overflow: hidden;
        margin: 0 -7px;
        @include SP {
          padding: 0 20px;
        }
        @include ANDR {
          padding: 0;
        }
        li {
          width: 25%;
          padding: 0 7px;
          @include SP {
            width: 100%;
            &:nth-child(3),
            &:nth-child(4) {
              display: none;
            }
          }
          &:not(:last-child) {
            @include SP {
              margin: 0 0 35px;
            }
          }
          .list-pro-img {
            display: block;
            border: 1px solid #dcdcdc;
            margin: 0 0 14px;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            @include aspect-ratio(280,210);
          }
          .list-pro-tit {
            color: #1a1a1a;
            margin: 0 0 7px;
            @include clamp(2);
            @include SP {
              margin: 0 0 18px;
            }
            p {
              @include SP {
                @include font(16,28);
              }
            }
          }
          .list-pro-btn {
            @include SP {
              @include font(16,28);
              background-size: 42px 16px;
              padding-left: 55px;
            }
          }
        }
      }
    }
  }

  &__product-brand {
    background: rgb(255,255,255);
    background: -moz-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 25%, rgba(0,0,0,1) 100%);
    background: -webkit-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 25%, rgba(0,0,0,1) 100%);
    background: linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 25%, rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#000000",GradientType=1);
    padding: 68px 0 92px;
    position: relative;
    z-index: 1;
    @include SP {
      padding: 42px 0;
    }
    &:after {
      content: '';
      background: url('../../img/top/bg_brand.png') right top/100% auto no-repeat;
      position: absolute;
      width: 690px;
      height: 613px;
      right: 0;
      top: 0;
      z-index: -1;
      @include SP {
        width: 190px;
        height: 168px;
      }
    }
    &--info {
      .cm-txt {
        text-align: center;
        @include SP {
          text-align: left;
          @include font(16,28);
        }
      }
    }
    &--list {
      
    }
  }

  &__event {
    background: url('../../img/top/bg_event.jpg') center center/cover no-repeat;
    padding: 70px 0 78px;
    @include SP {
      background: url('../../img/top/bg_event_sp.jpg') center center/cover no-repeat;
      padding: 45px 20px 50px;
    }
    @include ANDR {
      padding-left: 0;
      padding-right: 0;
    }
    &--list {
      padding: 5px 0 40px;
      @include SP {
        padding-top: 2px;
      }
      li {
        &:not(:last-child) {
          margin: 0 0 20px;
        }
        .list-event-wrap {
          @include flexbox();
          @include justify-content(space-between);
          @include flex-wrap(wrap);
          background: #fff;
          padding: 15px 60px;
          @include SP {
            padding: 20px;
            @include flex-direction(column);
          }
        }
        .list-event-title {
          width: 21%;
          @include SP {
            width: 100%
          }
          .date {
            @include font(16,28);
            font-weight: 700;
            @include SP {
              @include font(14,14);
            }
          }
        }
        .list-event-info {
          width: 79%;
          @include SP {
            width: 100%;
          }
          p {
            @include SP {
              @include font(14,24.5);
            }
          }
          .tit {
            font-weight: 700;
            @include font(16,28);
            border-bottom: 1px solid #8f8f8f;
            padding: 0 0 12px;
            margin: 0 0 12px;
            @include SP {
              margin-bottom: 8px;
              padding-bottom: 8px;
            }
          }
          .cm-button-icon {
            margin: 5px 0 0;
            @include SP {
              @include font(16,28);
              margin-top: 22px;
            }
          }
        }
      }
    }
  }

  &__message {
    background: $color_blue;
    padding: 78px 0 79px;
    position: relative;
    @include SP {
      padding: 48px 0 50px;
    }
    &--content {
      .box-content {
        max-width: 60%;
        @include SP {
          max-width: 100%;
        }
      }
      .tit {
        margin: 0 0 45px;
        @include SP {
          max-width: 275px;
          margin-bottom: 28px;
        }
      }
      .cm-txt {
        margin: 0 0 50px;
        @include SP {
          margin-bottom: 36px;
        }
      }
      .img {
        margin: 0 auto 40px;
        max-width: 188px;
      }
      .cm-button {
        margin: 0 0 0 18px;
        @include SP {
          margin: 0 auto;
          padding: 9px;
        }
      }
    }
    &--img {
      position: absolute;
      width: 40%;
      max-width: 460px;
      height: 100%;
      top: 0;
      right: 0;
      background-size: cover;
      background-position: top center;
      background-repeat: no-repeat;
      @include SP {
        display: none;
      }
    }
  }

  &__recruit {
    background: url('../../img/top/bg_recruit.jpg') 0 0/cover no-repeat;
    @include clearfix();
    padding: 75px 0;
    @include SP {
      padding: 25px 0;
      background: url('../../img/top/bg_recruit_sp.jpg') 0 0/cover no-repeat;
    }
    &--info {
      max-width: 600px;
      width: 100%;
      float: right;
      background: rgba(0,0,0,.3);
      padding: 25px 38px 35px;
      @include SP {
        padding: 20px 20px 25px;
      }
      .cm-title {
        @include flexbox();
        @include align-items(baseline);
        margin: 0 0 20px;
        @include SP {
          display: block;
        }
        .name-jp {
          margin: 0 20px 0 0;
          @include SP {
            margin-right: 0;
          }
        }
      }
      .cm-txt {
        font-feature-settings: "palt";
        -webkit-font-feature-settings: "palt";
      }
    }
  }

  &__banner {
    padding: 100px 0;
    @include SP {
      padding: 40px 0 45px;
    }
    &--list-full {
      padding: 0 0 40px;
      @include SP {
        padding-bottom: 10px;
      }
      .list-full-item {
        margin: 0 0 40px;
        display: block;
        img {
          width: 100%;
          display: block;
        }
        @include SP {
          margin-bottom: 20px;
        }
      }
    }
    &--list-half {
      @include flexbox();
      @include flex-wrap(wrap);
      @include justify-content(space-between);
      overflow: hidden;
      margin: 0 -15px;
      @include SP {
        @include flex-direction(column);
      }
      .list-half-item {
        padding: 0 15px;
        width: 50%;
        height: 200px;
        @include SP {
          width: 100%;
          margin-bottom: 35px;
          @include flex(0 0 100%);
          height: 130px;
        }
        a {
          @include flexbox();
          height: 100%;
          @include flex-direction(column);
          @include align-items(center);
          @include justify-content(center);
          position: relative;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
          .cm-button-icon {
            position: absolute;
            bottom: 10px;
            right: 20px;
            @include SP {
              @include font(13);
              background-size: 34px 13px;
              padding-left: 40px;
              position: static;
            }
          }
        }
        &.shop {
          a {
            .tit {
              color: #fff;
              text-align: center;
              @include SP {
                margin-bottom: 18px;
              }
              .jp {
                display: block;
                @include font(32,56);
                font-weight: 700;
                @include SP {
                  @include font(24,35);
                }
              }
              .en {
                display: block;
                @include SP {
                  @include font(12,14);
                }
              }
            }
          }
        }
        &.gallery {
          a {
            .tit {
              text-align: center;
              @include SP {
                margin-bottom: 5px;
              }
              .jp {
                color: #fff;
                @include font(28,49);
                font-weight: 700;
                @include SP {
                  @include font(20,35);
                }
              }
              .img {
                max-width: 196px;
                display: block;
                @include SP {
                  max-width: 140px;
                }
              }
            }
          }
        }
      }
    }
  }
}